import { Divider, Grid, SxProps, Typography, useTheme } from '@mui/material';
import { TknButton } from '../components/TknButton';
import Image from 'next/image';
import { GetStaticProps } from 'next';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { TokenUserRole } from '../types/enums/TokenUserRole';
import { TknPageContainer } from '../components/TknPageContainer';
import { useFeatureFlags } from '../hooks/useFeatureFlags';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { useButtonOnce } from '../hooks/useButtonOnce';
import { useAuth } from '../hooks/useAuth';
import { useEffect } from 'react';
import { localStorageService } from '../services/LocalStorageService';

interface HomeProps {
  role: TokenUserRole;
}

interface HomeStyle {
  container: SxProps;
  buttonContainer: SxProps;
  dividerContainer: SxProps;
  divider: SxProps;
}

const style: HomeStyle = {
  container: {
    height: '100%',
    minHeight: '100vh',
  },
  buttonContainer: {
    width: '100%',
    px: 'calc(40px - 15px)',
  },
  dividerContainer: {
    width: '100%',
    position: 'relative',
  },
  divider: {
    position: 'absolute',
    width: 'calc(100vw - 16px)',
    left: -8,
    borderColor: 'global.lightGrey',
  },
};

const Home = (props: HomeProps) => {
  const theme = useTheme();
  const { t } = useTranslation('splash');
  const router = useRouter();

  const { isGuestRoleFlagEnabled } = useFeatureFlags();

  const { enableGuestMode } = useAuth();
  const { disabled, handleClick } = useButtonOnce();

  const continueAsGuest = useCallback(() => {
    enableGuestMode();
    router.push('/guest/home');
  }, [enableGuestMode, router]);

  useEffect(() => {
    localStorageService.setInvitationDetails({
      token: router.query.token ? (router.query.token as string) : '',
      jobTitle: router.query.jobTitle ? (router.query.jobTitle as string) : '',
      venueOrganization: router.query.venueOrganization
        ? (router.query.venueOrganization as string)
        : '',
    });
  }, [router.query]);

  return (
    <TknPageContainer
      background={`${theme.palette.global.greyBlueGradient}, url("/splash.png")`}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={style.container}
        gap={3}
      >
        <Grid item mb={8.75}>
          <Image
            src="/logo.svg"
            alt="Token events logo"
            width="88"
            height="88"
          />
        </Grid>

        <Grid item>
          <Typography id="title" variant="h2">
            {t('title')}
          </Typography>
        </Grid>
        <Grid item>
          <Typography id="description" variant="bodyRegular">
            {t('description', { ns: 'splash', context: props.role })}
          </Typography>
        </Grid>

        <Grid item sx={style.buttonContainer}>
          <TknButton
            id="signUp"
            variant="contained"
            styleVariant="yellow"
            label={t('signUp')}
            size="large"
            disabled={disabled}
            onClick={handleClick(() => {
              router.push('/create-account');
            })}
          />
        </Grid>

        <Grid item sx={style.buttonContainer}>
          <TknButton
            id="login"
            variant="outlined"
            backgroundColor={theme.palette.global.blue}
            styleVariant="white"
            label={t('logIn')}
            size="large"
            disabled={disabled}
            onClick={handleClick(() => {
              router.push('/login');
            })}
          />
        </Grid>
        {isGuestRoleFlagEnabled() && (
          <>
            <Grid item sx={style.dividerContainer}>
              <Divider sx={style.divider} />
            </Grid>
            <Grid item sx={style.buttonContainer}>
              <TknButton
                id="guestMode"
                variant="outlined"
                styleVariant="white"
                label={t('continueAsGuest')}
                size="large"
                disabled={disabled}
                onClick={handleClick(continueAsGuest)}
              />
            </Grid>
          </>
        )}
      </Grid>
    </TknPageContainer>
  );
};

export const getStaticProps: GetStaticProps<HomeProps> = async ({ locale }) => {
  // TODO: the role should be taken from somewhere instead of hardcoded
  const role = TokenUserRole.FAN;
  return {
    props: {
      ...(await serverSideTranslations(locale as string, ['splash', 'error'])),
      role,
    },
  };
};

export default Home;
