import { useState, useCallback } from 'react';

export const useButtonOnce = () => {
  const [disabled, setDisabled] = useState<boolean>(false);

  const handleClick = useCallback((callback: Function) => {
    return () => {
      setDisabled(true);
      callback();
    };
  }, []);

  return { disabled, handleClick };
};
